import React from "react";

const repairLabelMap = new Map();
repairLabelMap.set("DOX", {categoryLabel: 'Désoxydation',repairLabel: 'Désoxydation'});
repairLabelMap.set("LCD", {categoryLabel: 'Ecran',repairLabel: 'Réparation écran'});
repairLabelMap.set("SWAP", {categoryLabel: 'Swap',repairLabel: 'Echange standard'});
repairLabelMap.set("BAT", {categoryLabel: 'Batterie',repairLabel: 'Remplacement batterie'});
repairLabelMap.set("BTN_VOL", {categoryLabel: 'Bouton Volume',repairLabel: 'Remplacement bouton volume'});
repairLabelMap.set("BTN_POW", {categoryLabel: 'Bouton On/Off',repairLabel: 'Remplacement bouton On/Off'});
repairLabelMap.set("BTN_HOME", {categoryLabel: 'Bouton Home',repairLabel: 'Remplacement bouton home'});
repairLabelMap.set("BTN_VIB", {categoryLabel: 'Vibreur',repairLabel: 'Remplacement vibreur'});
repairLabelMap.set("VIB", {categoryLabel: 'Vibreur',repairLabel: 'Remplacement vibreur'});
repairLabelMap.set("VIBREUR", {categoryLabel: 'Vibreur',repairLabel: 'Remplacement vibreur'});
repairLabelMap.set("PCONNECTOR", {categoryLabel: 'Connecteur de charge',repairLabel: 'Remplacement connecteur de charge'});
repairLabelMap.set("CON", {categoryLabel: 'Connecteur de charge',repairLabel: 'Remplacement connecteur de charge'});
repairLabelMap.set("CAM_REAR", {categoryLabel: 'Caméra arrière',repairLabel: 'Remplacement caméra arrière'});
repairLabelMap.set("CAM_FRONT", {categoryLabel: 'Caméra avant',repairLabel: 'Remplacement caméra avant'});
repairLabelMap.set("RNL", {categoryLabel: 'Mise à jour logiciel',repairLabel: 'Mise à jour logiciel'});
repairLabelMap.set("BACK_COVER", {categoryLabel: 'Face arrière',repairLabel: 'Réparation face arrière'});
repairLabelMap.set("TCT", {categoryLabel: 'Tactile',repairLabel: 'Remplacement tactile'});
repairLabelMap.set("HAUT_PARLEUR", {categoryLabel: 'Haut parleur',repairLabel: 'Remplacement haut parleur'});
repairLabelMap.set("HP", {categoryLabel: 'Haut parleur',repairLabel: 'Remplacement haut parleur'});
repairLabelMap.set("DIAG", {categoryLabel: 'Diagnostic',repairLabel: 'Diagnostic'});
repairLabelMap.set("SIMTR", {categoryLabel: 'Support Sim',repairLabel: 'Support Sim'});
repairLabelMap.set("ANT", {categoryLabel: 'Antenne réseau',repairLabel: 'Remplacement Antenne réseau'});
repairLabelMap.set("ECO", {categoryLabel: 'Ecouteur interne',repairLabel: 'Remplacement écouteur interne'});
repairLabelMap.set("MIC", {categoryLabel: 'Micro',repairLabel: 'Remplacement micro'});
repairLabelMap.set("SMA_KO", {categoryLabel: 'Smartphone non fonctionnel',repairLabel: 'Smartphone non fonctionnel'});
repairLabelMap.set("DOX", {categoryLabel: 'Smartphone oxydé',repairLabel: 'Smartphone oxydé'});

export function getRepairLabel(cat) {
    return repairLabelMap.has(cat) ? repairLabelMap.get(cat).repairLabel : cat;
}
export function getCategoryLabel(cat) {
    return repairLabelMap.has(cat) ? repairLabelMap.get(cat).categoryLabel : cat;
}

