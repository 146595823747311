import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useLocation} from 'react-router-dom';
import * as QueryString from "query-string"
import {logExtranetUser} from "../reducers/Extranet/Extranet.actions";
import LoadingComponent from "../components/LoadingComponent";
import InterventionComponent from "../components/InterventionComponent";

function ExtranetPage() {

    const extranet = useSelector(state => state.extranet);
    const dispatch = useDispatch();
    const location = useLocation();
    const params = QueryString.parse(location.search);

    let token = params.token;

    useEffect(() => {
        if (token !== undefined) {
            dispatch(logExtranetUser(token))
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    if (extranet.isLoading || !extranet.isLogged || extranet.isError) {
        return <LoadingComponent/>
    }

    return (
        <InterventionComponent/>
    );

}

export default ExtranetPage
