import 'semantic-ui-css/semantic.min.css'
import {Route, Switch} from "react-router-dom";
import ExtranetPage from "./views/ExtranetPage";
import 'moment/locale/fr';
import React, {useEffect} from "react";
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {library, config} from '@fortawesome/fontawesome-svg-core'
import {fas} from '@fortawesome/pro-solid-svg-icons'
import {far} from '@fortawesome/pro-regular-svg-icons'

function App() {
    library.add(fas)
    library.add(far)

    useEffect(() => {
        if(document.location.href.indexOf('mood') !== -1 ) {
            document.title = "mood réparations";
            document.getElementById("favicon").href = "https://www.mood.com/img/favicon.ico";
        }
    }, []);

    return (
        <>
            <ToastContainer/>
            <Switch>
                <Route path="/**" exact component={ExtranetPage}/>
            </Switch>
        </>
    );
}

export default App;
