import {Icon} from "semantic-ui-react";
import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import OrangeMoodLabel from "../../components/OrangeMoodLabel";

const historicTypeMap = new Map();

historicTypeMap.set(
    "QUOTE_INIT", {
        showOnExtranet: false
    }
);

historicTypeMap.set(
    "QUOTE_SENDED", {
            extranetTitle: 'Devis en attente de validation',
            extranetIcon: <FontAwesomeIcon size={"2x"} style={{marginLeft: '4px'}} icon="ballot-check" color="orange"/>,
            extranetText: <>Votre devis est en attente de validation de votre part, pour confirmer la prise en charge de votre réparation, nous vous invitons à vous rendre à nouveau dans votre boutique <OrangeMoodLabel label={"Orange"}/>.</>,
            extranetFeedIcon: <FontAwesomeIcon size={"2x"} style={{marginLeft: '4px'}} icon="ballot-check" color="orange"/>,
            extranetFeedText: <>Votre devis est en attente de validation de votre part, pour confirmer la prise en charge de votre réparation, nous vous invitons à vous rendre à nouveau dans votre boutique <OrangeMoodLabel label={"Orange"}/>.</>,
            showOnExtranet: true
    }
);

historicTypeMap.set(
    "NEW_QUOTE_UNSIGNED", {
        extranetTitle: '',
        extranetIcon: <FontAwesomeIcon size={"2x"} style={{marginLeft: '4px'}}  color={"rgba(0,0,0,.6)"} icon="file-signature"/>,
        extranetText: <>Vous n'avez pas répondu à la nouvelle proposition de devis qui vous a été transmise, votre téléphone n'a pas pu être réparé et va être retourné dans la boutique orange</>,
        extranetFeedIcon: <FontAwesomeIcon size={"2x"} style={{marginLeft: '4px'}}   color={"rgba(0,0,0,.6)"} icon="file-signature"/>,
        extranetFeedText: <>Vous n'avez pas répondu à la nouvelle proposition de devis qui vous a été transmise, votre téléphone n'a pas pu être réparé et va être retourné dans la boutique orange</>,
        showOnExtranet: true
    }
);

historicTypeMap.set(
    "QUOTE_VALIDATED", {
            extranetTitle: 'Dossier pris en charge',
            extranetIcon: <FontAwesomeIcon size={"2x"} style={{marginLeft: '4px'}}   color={"rgba(0,0,0,.6)"} icon="ballot-check" />,
            extranetText: <>Votre <OrangeMoodLabel label={"téléphone"}/> va prochainement être envoyé au centre de réparation</>,
            extranetFeedIcon: <FontAwesomeIcon size={"2x"} style={{marginLeft: '4px'}}  color={"rgba(0,0,0,.6)"} icon="ballot-check" />,
            extranetFeedText: <>Suite au diagnostic en magasin <OrangeMoodLabel label={"Orange"}/>, vous avez donné votre accord pour le devis suivant :</>,
            showOnExtranet: true
    }
);

historicTypeMap.set(
    "TRANSIT_ORANGE_SAVE", {
            extranetTitle: 'En transit',
            extranetIcon: <FontAwesomeIcon size={"3x"} color={"rgba(0,0,0,.6)"} icon="person-carry"/>,
            extranetText: <>Votre <OrangeMoodLabel label={"téléphone"}/> est en transit vers le centre de réparation</>,
            extranetFeedIcon: <FontAwesomeIcon size={"3x"} color={"rgba(0,0,0,.6)"} icon="person-carry"/>,
            extranetFeedText: <>Votre <OrangeMoodLabel label={"téléphone"}/> est en transit vers le centre de réparation</>,
            showOnExtranet: true
    }
);

historicTypeMap.set(
    "PRODUCT_RECEIVED_SAVE", {
            extranetTitle: 'Produit reçu en centre de réparation',
            extranetIcon: <FontAwesomeIcon size={"2x"} color={"rgba(0,0,0,.6)"} icon="box-open"/>,
            extranetText: 'Produit bien reçu dans le centre de réparation',
            extranetFeedIcon: <FontAwesomeIcon size={"2x"} color={"rgba(0,0,0,.6)"} icon="box-open"/>,
            extranetFeedText: <>Votre <OrangeMoodLabel label={"téléphone"}/> a bien été reçu dans le centre de réparation</>,
            showOnExtranet: true
    }
);

historicTypeMap.set(
    "NEW_QUOTE_PROPOSAL", {
            extranetTitle: 'Suite au diagnostic un nouveau devis a été établi',
            extranetIcon: <FontAwesomeIcon size={"2x"} style={{marginLeft: '5px'}}  icon="ballot" color={"orange"}/>,
            extranetText: 'La réparation a été mise en attente en attendant votre consentement au nouveau devis',
            extranetFeedIcon: <FontAwesomeIcon size={"2x"} style={{marginLeft: '5px'}}  icon="ballot" color={"orange"}/>,
            extranetFeedText: 'Un nouveau diagnostic a été fait avant réparation, donnez votre accord pour celui-ci',
            showOnExtranet: true
    }
);

historicTypeMap.set(
    "NEW_QUOTE_ACCEPTED", {
            extranetTitle: 'Vous avez accepté le nouveau devis',
            extranetIcon: <FontAwesomeIcon size={"2x"} style={{marginLeft: '5px'}} icon="ballot-check" color={"green"}/>,
            extranetText: <>Votre <OrangeMoodLabel label={"téléphone"}/> est en cours de réparation</>,
            extranetFeedIcon: <FontAwesomeIcon size={"2x"} style={{marginLeft: '5px'}} icon="ballot-check" color={"green"}/>,
            extranetFeedText: <>Vous avez accepté le nouveau devis, votre <OrangeMoodLabel label={"téléphone"}/> est en cours de réparation</>,
            showOnExtranet: true
    }
);
historicTypeMap.set(
    "NEW_QUOTE_REFUSED", {
            extranetTitle: 'Vous avez refusé le nouveau devis',
            extranetIcon: <FontAwesomeIcon size={"2x"} style={{marginLeft: '5px'}} icon="ballot-check" color={"red"}/>,
            extranetText: <>Votre <OrangeMoodLabel label={"téléphone"}/> va vous être renvoyé</>,
            extranetFeedIcon: <FontAwesomeIcon size={"2x"} style={{marginLeft: '5px'}} icon="ballot-check" color={"red"}/>,
            extranetFeedText: <>Vous avez refusé le nouveau devis, votre <OrangeMoodLabel label={"téléphone"}/> va vous être renvoyé</>,
            showOnExtranet: true
    }
);

historicTypeMap.set(
    "REPAIR_IN_PROGRESS", {
            extranetTitle: 'Produit reçu en centre de réparation',
            extranetIcon: <FontAwesomeIcon size={"2x"} color={"rgba(0,0,0,.6)"} icon="clinic-medical"/>,
            extranetText: <>Votre <OrangeMoodLabel label={"téléphone"}/> est en cours de réparation</>,
            extranetFeedIcon: <FontAwesomeIcon size={"2x"} color={"rgba(0,0,0,.6)"} icon="clinic-medical"/>,
            extranetFeedText: <>Votre <OrangeMoodLabel label={"téléphone"}/> est en cours de réparation, il va être diagnostiqué puis réparé</>,
            showOnExtranet: true
    }
);

historicTypeMap.set(
    "PRODUCT_REPAIRED", {
            extranetTitle: <>Le <OrangeMoodLabel label={"téléphone"}/> a été réparé</>,
            extranetIcon: <FontAwesomeIcon size={"2x"} icon={"tools"} color={"green"}/>,
            extranetText: <>Votre <OrangeMoodLabel label={"téléphone"}/> va prochainement être envoyé dans votre boutique <OrangeMoodLabel label={"Orange"}/></>,
            extranetFeedIcon: <FontAwesomeIcon size={"2x"} icon={"tools"} color={"green"}/>,
            extranetFeedText: <>Votre <OrangeMoodLabel label={"téléphone"}/> a été réparé et sera prochainement disponible en boutique <OrangeMoodLabel label={"Orange"}/></>,
            showOnExtranet: true
    }
);

historicTypeMap.set("PRODUCT_NOT_REPAIRED",
    {
            extranetTitle: <>Le <OrangeMoodLabel label={"téléphone"}/> n'a pas pu être réparé</>,
            extranetIcon: <FontAwesomeIcon size={"2x"} icon={"tools"} color={"red"}/>,
            extranetText: <>Votre <OrangeMoodLabel label={"téléphone"}/> non réparé va prochainement être envoyé dans votre boutique <OrangeMoodLabel label={"Orange"}/></>,
            extranetFeedIcon: <FontAwesomeIcon size={"2x"} icon={"tools"} color={"red"}/>,
            extranetFeedText: <>Votre <OrangeMoodLabel label={"téléphone"}/> n`a pas pu être réparé et sera prochainement disponible en boutique <OrangeMoodLabel label={"Orange"}/></>,
            showOnExtranet: true
    }
);

historicTypeMap.set(
    "TRANSIT_SAVE_ORANGE", {
            extranetTitle: 'En transit',
            extranetIcon: <FontAwesomeIcon size={"3x"} color={"rgba(0,0,0,.6)"} icon="person-carry"  flip="horizontal"/>,
            extranetText: <>Votre <OrangeMoodLabel label={"téléphone"}/> est en transit vers votre boutique <OrangeMoodLabel label={"Orange"}/></>,
            extranetFeedIcon: <FontAwesomeIcon size={"3x"} color={"rgba(0,0,0,.6)"} icon="person-carry"  flip="horizontal"/>,
            extranetFeedText: <>Votre <OrangeMoodLabel label={"téléphone"}/> est en transit vers votre boutique <OrangeMoodLabel label={"Orange"}/></>,
            showOnExtranet: true
    }
);

historicTypeMap.set(
    "PRODUCT_RECEIVED_ORANGE", {
            extranetTitle: <><OrangeMoodLabel label={"Téléphone"}/> disponible</>,
            extranetIcon: <FontAwesomeIcon size={"2x"} color={"rgba(0,0,0,.6)"} icon="box-check"/>,
            extranetText: <>Votre <OrangeMoodLabel label={"téléphone"}/> vous attend dans votre boutique <OrangeMoodLabel label={"Orange"}/></>,
            extranetFeedIcon: <FontAwesomeIcon size={"2x"} color={"rgba(0,0,0,.6)"} icon="box-check"/>,
            extranetFeedText: <>Votre <OrangeMoodLabel label={"téléphone"}/> vous attend dans votre boutique <OrangeMoodLabel label={"Orange"}/></>,
            showOnExtranet: true
    }
);

historicTypeMap.set(
    "CANCELED", {
            extranetTitle: <>Dossier annulé par <OrangeMoodLabel label={"Orange"}/></>,
            extranetIcon: <Icon name='exchange' color='orange'/>,
            extranetText: <>Dossier annulé par <OrangeMoodLabel label={"Orange"}/></>,
            extranetFeedIcon: <Icon name='exchange' color='orange'/>,
            extranetFeedText: <>Dossier annulé par <OrangeMoodLabel label={"Orange"}/></>,
            showOnExtranet: true
    }
);

historicTypeMap.set(
    "FINISHED", {
            extranetTitle: 'Dossier clos',
            extranetIcon: <FontAwesomeIcon size={"2x"} style={{marginLeft: '5px'}} icon="mobile-alt" color={"green"}/>,
            extranetText: <>Votre <OrangeMoodLabel label={"téléphone"}/>  vous a été restitué</>,
            extranetFeedIcon: <FontAwesomeIcon size={"2x"} style={{marginLeft: '5px'}} icon="mobile-alt" color={"green"}/>,
            extranetFeedText: <>Vous avez récupéré votre <OrangeMoodLabel label={"téléphone"}/></>,
            showOnExtranet: true
    }
);

// Fin des status

// Actions
// TODO : deplacer les actions dans un autre fichier
historicTypeMap.set(
    "NEW_QUOTE_PROPOSAL_RELANCE", {
        showOnExtranet: false
    }
);

historicTypeMap.set(
    "RECEIVED_PRODUCT_IN_SHOP_RELANCE", {
        showOnExtranet: false
    }
);

historicTypeMap.set(
    "TRANSFERT_CEGID_TICKET_SUCCESS", {
        extranetTitle: 'Le transfert vers la caisse a réussi',
        extranetIcon: <Icon name='check' color={"green"}/>,
        extranetText: 'Le transfert vers la caisse a réussi',
        extranetFeedIcon: <Icon name='check' color={"green"}/>,
        extranetFeedText: 'Le transfert vers la caisse a réussi',
        showOnExtranet: false
    }
);

historicTypeMap.set(
    "TRANSFERT_CEGID_TICKET_ERROR", {
        extranetTitle: 'Le transfert vers la caisse a échoué',
        extranetIcon: <Icon name='check' color={"red"}/>,
        extranetText: 'Le transfert vers la caisse a échoué',
        extranetFeedIcon: <Icon name='check' color={"red"}/>,
        extranetFeedText: 'Le transfert vers la caisse a échoué',
        showOnExtranet: false
    }
);

historicTypeMap.set(
    "SAV_REQUESTED", {
        extranetTitle: 'Votre dossier est traiter en SAV',
        extranetIcon: <Icon name='exchange' color='green'/>,
        extranetText: 'Votre dossier à été déclaré en SAV',
        extranetFeedIcon: <Icon name='exchange' color='green'/>,
        extranetFeedText: 'Votre dossier à été déclaré en SAV',
        showOnExtranet: true
    }
);

historicTypeMap.set(
    "SAV_CREATION", {
        extranetTitle: 'Le dossier est passé en SAV',
        extranetIcon: <Icon name='exchange' color='orange'/>,
        extranetText: 'Le dossier est passé en SAV',
        extranetFeedIcon: <Icon name='exchange' color='orange'/>,
        extranetFeedText: 'Le dossier est passé en SAV',
        showOnExtranet: true
    }
);

// Fin des actions


// Actions liés aux colis
// TODO : ne devrait plus exister ici, voir si on peux faire un batch de migration pour transférer dans parcel_historic

historicTypeMap.set(
    "PACKED", {
        extranetTitle: 'Dossier validé',
        extranetIcon: <Icon name='inbox'/>,
        extranetText: 'Votre téléphone va prochainement être envoyé au centre de réparation',
        extranetFeedIcon: <Icon name='handshake outline' color='green'/>,
        extranetFeedText: 'Suite au diagnostic en magasin Orange, vous avez donné votre accord pour le devis suivant :',
        showOnExtranet: false
    }
);
historicTypeMap.set(
    "COURIRER_SHIPMENT_REQUESTED", {
        extranetTitle: 'Dossier prêt à être envoyé',
        extranetIcon: <Icon name='box'/>,
        extranetText: 'Votre téléphone va prochainement être envoyé au centre de réparation',
        extranetFeedIcon: <Icon name='box'/>,
        extranetFeedText: 'Votre téléphone est prêt pour le transfert',
        showOnExtranet: true
    }
);
historicTypeMap.set(
    "COURIRER_SHIPMENT_HANDLED", {
        extranetTitle: 'En transit',
        extranetIcon: <Icon name='truck'/>,
        extranetText: 'Votre téléphone est en transit vers le centre de réparation',
        extranetFeedIcon: <FontAwesomeIcon size={"3x"} color={"rgba(0,0,0,.6)"} icon="person-carry"/>,
        extranetFeedText: 'Votre téléphone est en transit vers le centre de réparation',
        showOnExtranet: true
    }
);

historicTypeMap.set(
    "SHIPMENT_IN_PROGRESS", {
        extranetTitle: 'En transit',
        extranetIcon: <Icon name='truck'/>,
        extranetText: 'Votre téléphone est en transit vers le centre de réparation',
        extranetFeedIcon: <FontAwesomeIcon size={"3x"} color={"rgba(0,0,0,.6)"} icon="person-carry"/>,
        extranetFeedText: 'Votre téléphone est en transit vers le centre de réparation',
        showOnExtranet: true
    }
);


historicTypeMap.set(
    "BY_SHOP_SHIPMENT_REQUESTED", {
        extranetTitle: 'En transit',
        extranetIcon: <Icon name='street view'/>,
        extranetText: 'Votre téléphone est en transit vers le centre de réparation',
        extranetFeedIcon: <FontAwesomeIcon size={"3x"} color={"rgba(0,0,0,.6)"} icon="person-carry"/>,
        extranetFeedText: 'Votre téléphone est en transit vers le centre de réparation',
        showOnExtranet: true
    }
);

historicTypeMap.set(
    "BY_SHOP_SHIPMENT_HANDLED", {
        extranetTitle: 'En transit',
        extranetIcon: <Icon name='truck'/>,
        extranetText: 'Votre téléphone est en transit vers le centre de réparation',
        extranetFeedIcon: <FontAwesomeIcon size={"3x"} color={"rgba(0,0,0,.6)"} icon="person-carry"/>,
        extranetFeedText: 'Votre téléphone est en transit vers le centre de réparation',
        showOnExtranet: true
    }
);
historicTypeMap.set(
    "CHRONOPOST_SHIPMENT_REQUESTED", {
        extranetTitle: 'Dossier prêt à être envoyé',
        extranetIcon: <Icon name='box'/>,
        extranetText: 'Votre téléphone va prochainement être envoyé au centre de réparation',
        extranetFeedIcon: <Icon name='box'/>,
        extranetFeedText: 'Votre téléphone va prochainement être envoyé au centre de réparation',
        showOnExtranet: true
    }
);
historicTypeMap.set(
    "CHRONOPOST_SHIPMENT_HANDLED", {
        extranetTitle: 'En transit',
        extranetIcon: <Icon name='truck'/>,
        extranetText: 'Votre téléphone est en transit vers le centre de réparation',
        extranetFeedIcon: <FontAwesomeIcon size={"3x"} color={"rgba(0,0,0,.6)"} icon="person-carry"/>,
        extranetFeedText: 'Votre téléphone est en transit vers le centre de réparation',
        showOnExtranet: true
    }
);

historicTypeMap.set(
    "SENDED_BACK_BY_COURIER_TRANSITING", {
        extranetTitle: 'Le téléphone est en route vers votre magasin Orange',
        extranetIcon: <Icon name='bicycle' flipped='horizontally'/>,
        extranetText: 'Il sera bientôt disponible, attendez la notification de mise à disposition',
        extranetFeedIcon: <Icon name='bicycle' flipped='horizontally'/>,
        extranetFeedText: 'Votre téléphone est en route vers votre magasin Orange',
        showOnExtranet: true
    }
);

historicTypeMap.set(
    "NEW_REPAIR_DATE", {
        extranetTitle: 'La date de restitution de votre téléphone réparé a changé',
        extranetIcon: <FontAwesomeIcon size={"2x"} style={{marginLeft: '5px'}} icon="clock" color={"orange"}/>,
        extranetText: 'La date de restitution de votre téléphone réparé a changé',
        extranetFeedIcon: <FontAwesomeIcon size={"2x"} style={{marginLeft: '3px'}} icon="clock" color={"orange"}/>,
        extranetFeedText: 'Le retour est prévu pour le ',
        showOnExtranet: true
}
);

historicTypeMap.set(
    "SENDED_BACK_BY_COURIER_RECEIVED", {
        extranetTitle: 'Le téléphone est dans votre magasin Orange',
        extranetIcon: <Icon name='bicycle' flipped='horizontally'/>,
        extranetText: 'Il est disponible',
        extranetFeedIcon: <Icon name='bicycle' flipped='horizontally'/>,
        extranetFeedText: 'Vous pouvez vous rendre en boutique orange',
        showOnExtranet: true
    }
);

historicTypeMap.set(
    "DISCOUNT", {
        extranetTitle: 'Le prix de votre réparation a été mis à jour. Il est passé de',
        extranetIcon: <FontAwesomeIcon size={"2x"} color={"rgba(0,0,0,.6)"} style={{marginLeft: '5px'}} icon="percent" />,
        extranetText: 'Le prix de votre réparation a été mis à jour. Il est passé de',
        extranetFeedIcon: <FontAwesomeIcon size={"2x"} color={"rgba(0,0,0,.6)"} style={{marginLeft: '5px'}} icon="percent" />,
        extranetFeedText: 'Le prix de votre réparation a été mis à jour. Il est passé de',
        showOnExtranet: true
    }
);

historicTypeMap.set(
    "SENDED_BACK_BY_SHOP_TRANSITING", {
        extranetTitle: 'Le téléphone est en route vers votre magasin Orange',
        extranetIcon: <Icon name='bicycle' flipped='horizontally'/>,
        extranetText: 'Il sera bientôt disponible, attendez la notification de mise à disposition',
        extranetFeedIcon: <Icon name='bicycle' flipped='horizontally'/>,
        extranetFeedText: 'Votre téléphone est en route vers votre magasin Orange',
        showOnExtranet: true
    }
);
historicTypeMap.set(
    "SENDED_BACK_BY_CRHONOPOST_TRANSITING", {
        extranetTitle: 'Le téléphone est en route vers votre magasin Orange',
        extranetIcon: <Icon name='truck' flipped='horizontally'/>,
        extranetText: 'Il sera bientôt disponible, attendez la notification de mise à disposition',
        extranetFeedIcon: <Icon name='truck' flipped='horizontally'/>,
        extranetFeedText: 'Votre téléphone est en route vers votre magasin Orange',
        showOnExtranet: true
    }
);

export default historicTypeMap;
