import {Grid, Header, Image, Loader, Message, Segment} from 'semantic-ui-react'
import React from 'react';
import {useSelector} from "react-redux";
import logoOrange from './logo-orange.png';
import logoMood from './logo-mood.png';

function WaitingComponent() {

    const extranet = useSelector(state => state.extranet);


    return (


        <Grid textAlign='center' style={{height: '100vh'}} verticalAlign='middle'>
            <Grid.Column style={{maxWidth: 450}}>
                <Header as='h2' color='teal' textAlign='center'>
                    <Segment>
                        <Grid>
                            <Grid.Column verticalAlign='middle'>
                                {document.location.href.indexOf('mood') !== -1 ?
                                    <Image src={logoMood}  centered/> :
                                    <Image src={logoOrange} size='tiny' centered/>}
                            </Grid.Column>
                        </Grid>
                    </Segment>
                    <Segment>
                        {!extranet.token ? <Message>Merci d'utiliser le lien qui vous a été fournit pour consulter votre
                            dossier</Message> : null}
                        {extranet.isLoading ? <><Loader active inline='centered'/><Message info>Veuillez patienter, nous
                                récupérons votre dossier.</Message></> :
                            extranet.isLogged ? <Message>Connecté</Message> :
                                extranet.isError ? <Message error>Une erreur est survenue</Message> : null
                        }
                    </Segment>
                </Header>
            </Grid.Column>
        </Grid>


    );

}

export default WaitingComponent
