import axios from "axios";
import {toast} from "react-toastify";

export const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BACKOFFICE_URL+'/api/v1/',
    timeout: 15000,
    withCredentials: true
});

export const axiosInstanceAdmin = axios.create({
    baseURL: process.env.REACT_APP_BACKOFFICE_URL+'/',
    timeout: 15000,
    withCredentials: true
});

// Axios Interceptors, every axiosInstance call will pass here
//
axiosInstance.interceptors.response.use(function (response) {
    return response;
}, function (error) {

    console.log(error);
    toast.error('Une erreur est survenue', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

    return Promise.reject(error);
});

export default axiosInstance;
