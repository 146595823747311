import {axiosInstance} from "../../utils/axiosInstance";
import {
    FETCH_EXTRANET_INFOS,
    FETCH_EXTRANET_INFOS_ERROR,
    FETCH_EXTRANET_INFOS_SUCCESS,
    SET_EXTRANET_TOKEN
} from "./Extranet.types";


export function logExtranetUser(token) {
    return async function (dispatch, getState) {

        dispatch({type: SET_EXTRANET_TOKEN, token: token})

        dispatch({type: FETCH_EXTRANET_INFOS})

        axiosInstance.get("/extranet/login/" + token)
            .then((response) => {
                console.log(response.data)
                dispatch({
                    type: FETCH_EXTRANET_INFOS_SUCCESS,
                    payload: response.data
                });
            })
            .catch((error) => {
                dispatch({
                    type: FETCH_EXTRANET_INFOS_ERROR,
                });
            });
    }
}
