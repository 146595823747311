import {Button, Container, Divider, Grid, Header, Icon, Label, Message, Segment} from 'semantic-ui-react'
import React from 'react';
import {useSelector} from "react-redux";
import statusMap from "../utils/status/interventionStatus"
import ExtranetHeaderMenu from "./ExtranetHeaderMenu/ExtranetHeaderMenu";
import Moment from "react-moment";
import InterventionHistoricExtranet from "./InterventionHistoricExtranet";
import OrangeMoodLabel from "./OrangeMoodLabel";

function InterventionComponent() {

    const extranet = useSelector(state => state.extranet);

    return (

        <>
            <Grid padded>
                <ExtranetHeaderMenu/>
            </Grid>
            <Grid centered fluid>
                <Grid.Column width={16} id="content-extranet" centered>
                    <Segment id="main-segment" fluid>
                        <Container fluid>

                            <Header as='h3'>Suivi de votre dossier de réparation {extranet.intervention.isSav ? <span style={{float:'right'}}><Label color={"teal"}>Retour garantie réparation</Label></span> : null}</Header>
                            <Divider />
                            <Container textAlign='justified'><p>Suite à votre passage en boutique <OrangeMoodLabel label={"Orange"}/>, vous avez initié une demande de réparation pour votre <OrangeMoodLabel label={"téléphone"}/> <b>{extranet.intervention.productBrandName} {extranet.intervention.productModelName}</b>.
                                Votre dossier a été enregistré sous le numéro <b>ORG_{extranet.intervention.interventionId}</b>. Voici les éléments actualisés. </p></Container>
                            <Divider />

                            <Header as='h3' block attached='top'>
                                DOSSIER ORG_{extranet.intervention.interventionId}
                            </Header>
                            <Segment attached>


                                <Grid>

                                    <Grid.Column mobile={16} tablet={8} computer={8}>

                                        <Segment >
                                            <Header as='h4' >
                                                <Icon name='calendar alternate outline' />
                                                <Header.Content>
                                                    Date de prise en charge
                                                    <Header.Subheader><Moment format="dddd DD MMMM YYYY [à] HH:mm" locale="fr">{extranet.intervention.creationDate}</Moment></Header.Subheader>
                                                </Header.Content>
                                            </Header>
                                        </Segment>

                                    </Grid.Column>

                                    <Grid.Column mobile={16} tablet={8} computer={8}>
                                        <Segment >
                                            <Header as='h4'>
                                                <Icon name='point' />
                                                <Header.Content>
                                                    Magasin de prise en charge
                                                    <Header.Subheader>{extranet.intervention.shopPartnerName}</Header.Subheader>
                                                </Header.Content>
                                            </Header>
                                        </Segment>
                                    </Grid.Column>
                                </Grid>

                                <Divider horizontal>
                                    <Header as='h4'>
                                        Informations
                                    </Header>
                                </Divider>

                                <Grid>

                                    <Grid.Column mobile={16} tablet={8} computer={8}>

                                        <Segment >
                                            <Label attached='top'>Client</Label>
                                            <Header as='h4' >
                                                <Icon name='user' />
                                                <Header.Content>
                                                    Nom : {extranet.intervention.customerLastName}<br/>
                                                    Prénom : {extranet.intervention.customerFirstName}
                                                    {extranet.intervention.customerCompanyNamee ?
                                                        <><br/><b>Société :
                                                            extranet.intervention.customerCompanyNamee </b></> : null}<br/>
                                                </Header.Content>
                                            </Header>
                                        </Segment>

                                    </Grid.Column>

                                    <Grid.Column mobile={16} tablet={8} computer={8}>
                                        <Segment >
                                            <Label attached='top'>Appareil</Label>
                                            <Header as='h4'>
                                                <Icon name='mobile alternate' />
                                                <Header.Content>
                                                    {extranet.intervention.productBrandName}
                                                    <Header.Subheader>{extranet.intervention.productModelName}</Header.Subheader>
                                                    <Header.Subheader><b>imei : </b>{extranet.intervention.productImei}</Header.Subheader>
                                                </Header.Content>
                                            </Header>
                                        </Segment>
                                    </Grid.Column>
                                </Grid>

                            </Segment>

                            <Header as='h3' block attached='top'>
                                Statut du dossier
                            </Header>

                            {!statusMap.get(extranet.intervention.interventionStatus) ? null :

                                <Message icon success attached>
                                    {statusMap.get(extranet.intervention.interventionStatus).extranetIcon}
                                    <Message.Content style={{marginLeft: "15px"}}>
                                        <Message.Header>{statusMap.get(extranet.intervention.interventionStatus).extranetTitle}</Message.Header>
                                        {statusMap.get(extranet.intervention.interventionStatus).extranetText}
                                    </Message.Content>
                                </Message>

                            }

                            <Header as='h3' block attached='top'>
                                Détails
                            </Header>
                            <Segment attached>
                                <InterventionHistoricExtranet/>
                            </Segment>

                            <Header as='h3' block attached='top'>
                                Un problème ?
                            </Header>
                            <Segment attached>

                                    <Header as='h3' >
                                        <Button fluid color='instagram' href="mailto:orange@save.store">
                                            <Icon name='help' /> Contacter notre support
                                        </Button>
                                    </Header>

                            </Segment>
                        </Container>
                    </Segment>
                </Grid.Column>
            </Grid>
        </>


    );

}

export default InterventionComponent
