import {Menu} from 'semantic-ui-react'
import logoOrange from './logo-orange.png';
import logoMood from './logo_mood_baseline.png';
import Responsive from "semantic-ui-react/dist/commonjs/addons/Responsive";
import {useSelector} from "react-redux";

function ExtranetHeaderMenu() {

    const type = useSelector(state => state.extranet.intervention.shopPartnerType);

    return (
        type === 'MOOD' ?
            <Menu borderless fluid fixed="top" position='right'>
                <Menu.Menu position='right'>
                    <Menu.Item>
                        <img alt="logo-orange" src={logoMood} style={{width: '180px'}}/>
                    </Menu.Item>
                </Menu.Menu>
            </Menu> :
            <Menu inverted borderless size='small' fluid fixed="top" position='right'>

                <Menu.Item>
                    <img alt="logo-orange" src={logoOrange}/>
                </Menu.Item>
                <Menu.Item>
                    <Responsive minWidth={Responsive.onlyMobile.maxWidth}>
                        <h2>Orange Réparation</h2>
                    </Responsive>
                    <Responsive maxWidth={Responsive.onlyMobile.maxWidth}>
                        <h4>Orange Réparation</h4>
                    </Responsive>
                </Menu.Item>
            </Menu>
    );

}

export default ExtranetHeaderMenu
