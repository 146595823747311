import {
    FETCH_EXTRANET_INFOS,
    FETCH_EXTRANET_INFOS_ERROR,
    FETCH_EXTRANET_INFOS_SUCCESS,
    SET_EXTRANET_TOKEN
} from './Extranet.types'

const INITIAL_STATE = {
    token: "",
    intervention: "",
    isLogged: false,
    isLoading: false,
    isError: false
};

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case SET_EXTRANET_TOKEN: {
            return ({
                ...state,
                token: action.token
            })
        }

        case FETCH_EXTRANET_INFOS: {
            return ({
                ...state,
                isLoading: true
            })
        }

        case FETCH_EXTRANET_INFOS_SUCCESS: {
            return ({
                ...state,
                intervention: action.payload,
                isLogged: true,
                isLoading: false,
            })
        }

        case FETCH_EXTRANET_INFOS_ERROR: {
            return ({
                ...state,
                isLogged: false,
                isLoading: false,
                isError: true
            })
        }

        default:
            return state;
    }
};

export default reducer;
