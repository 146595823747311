import { combineReducers } from 'redux';

import extranetReducer from '../reducers/Extranet/Extranet.reducer';
import userReducer from '../reducers/User/user.reducer';

const rootReducer = combineReducers({
    extranet:extranetReducer,
    user: userReducer,
});

export default rootReducer;
