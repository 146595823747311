import {Button, Card, Feed, Header, Icon, Label, Message, Modal, Segment} from 'semantic-ui-react'
import {useDispatch, useSelector} from "react-redux";
import React, {useState} from "react";
import Moment from "react-moment";
import statusMap from "../utils/status/interventionStatus"
import NumberFormat from "react-number-format";
import axiosInstance from "../utils/axiosInstance";
import {logExtranetUser} from "../reducers/Extranet/Extranet.actions";
import SignaturePad from 'react-signature-canvas'
import {getRepairLabel} from "../utils/repairLabels";
import {isOnlySmaKo} from "../utils/intervention-utils";

function InterventionHistoricExtranet(props) {

    const [openModal, setOpenModal] = useState(false);
    const [openRefusalModal, setOpenRefusalModal] = useState(false);
    const [sigPad, setSigPad] = useState(null);
    const [sigError, setSigError] = useState(false);
    const dispatch = useDispatch();
    const extranet = useSelector(state => state.extranet);

    const acceptNewQuote = () => {

        if (sigPad.isEmpty()) {
            setSigError(true);
            return;
        }
        setSigError(false);

        axiosInstance.post("/extranet/" + extranet.intervention.interventionId + '/accept',
            sigPad == null ? null : sigPad.toDataURL())
            .then((response) => {
                dispatch(logExtranetUser(extranet.token))
                setOpenModal(false)
            });
    }

    const deniedNewQuote = () => {


        axiosInstance.get("/extranet/" + extranet.intervention.interventionId + '/denied')
            .then((response) => {
                dispatch(logExtranetUser(extranet.token))
                setOpenRefusalModal(false)
            });
    }


    const repairList = [];
    if (extranet.items) {
        for (const item of extranet.items) {
            if (!item.status) {
                repairList.push({
                    name: item.name,
                    priceTtc: item.priceTtc
                })
            }
        }
    }
    console.log(sigPad)

    const toto = (ref) => {
        console.log('tt');
        setSigPad(ref);
    }

    const erase = () => {
        sigPad.clear();
    }

    return (
        <>
            <Feed>

                {extranet.intervention.fullHistoric.map((historicElement) => (

                    !((statusMap.get(historicElement.status) || statusMap.get(historicElement.type))
                        && statusMap.get(historicElement.status) && statusMap.get(historicElement.status).showOnExtranet ||  statusMap.get(historicElement.type) && statusMap.get(historicElement.type).showOnExtranet)?  null :
                            <Feed.Event>
                                <Feed.Label>
                                    {statusMap.get(historicElement.status) ? statusMap.get(historicElement.status).extranetFeedIcon :
                                        statusMap.get(historicElement.type) ? statusMap.get(historicElement.type).extranetFeedIcon :
                                        <Icon name='question'/>}
                                </Feed.Label>
                                <Feed.Content>
                                    <Feed.Date><Moment format="DD MMMM YYYY[,] HH:mm"
                                                       locale="fr">{historicElement.date}</Moment>
                                    </Feed.Date>

                                    {historicElement.status === 'QUOTE_VALIDATED' && isOnlySmaKo(extranet.intervention) || extranet.intervention.items.filter((r) => r.category === 'DOX').length > 0 ?
                                        <Feed.Summary>
                                            Suite a votre passage en magasin, un devis complet n'a pas pu être réalisé,
                                            nous vous le fournirons dans les plus brefs délais
                                        </Feed.Summary> :
                                        <Feed.Summary>
                                            {statusMap.get(historicElement.status) ? statusMap.get(historicElement.status).extranetFeedText : historicElement.status}
                                        </Feed.Summary>
                                    }

                                    {historicElement.type === 'NEW_REPAIR_DATE' ?
                                        <>
                                            <Feed.Summary>
                                                {statusMap.get(historicElement.type) ? statusMap.get(historicElement.type).extranetTitle
                                                    : historicElement.status} <br/>
                                            </Feed.Summary>
                                            <Feed.Extra>
                                                <Icon name='calendar check outline'/> {historicElement.comment}<br/>
                                            </Feed.Extra>
                                        </>: null}

                                    {historicElement.type === 'DISCOUNT' ?
                                        <>
                                            <Feed.Event>
                                                <Feed.Content>
                                                    <Feed.Summary>
                                                        Le prix de votre réparation a été mis à jour. Il est passé de {historicElement.datas.priceTtcBeforeDiscount}€ à {historicElement.datas.priceTtcAfterDiscount}€ (réduction de {historicElement.datas.discountRate}%)
                                                    </Feed.Summary>
                                                </Feed.Content>
                                            </Feed.Event>
                                        </>: null}

                                    {historicElement.datas && historicElement.datas.estimatedDate ?
                                        <Feed.Extra>
                                            <Icon name='calendar check outline'/>prévu pour le {historicElement.datas.estimatedDate}<br/>
                                        </Feed.Extra> : null}

                                    {historicElement.comment && historicElement.type != 'NEW_REPAIR_DATE'?
                                        <Feed.Meta>
                                            <Feed.Like><Icon name='quote left'/>{historicElement.comment}&nbsp;<Icon
                                                name='quote right'/></Feed.Like>
                                        </Feed.Meta> : null}

                                    {historicElement.datas && historicElement.datas.diag ?
                                        <Feed.Summary style={{marginTop: '2px'}}>
                                            {historicElement.datas.diag.map((repair) => (

                                                <Label basic size='small' style={{marginBottom: '2px'}}>{getRepairLabel(repair.name)}

                                                    {repair.priceTtc > 0 ? <Label.Detail><NumberFormat
                                                        value={repair.priceTtc}
                                                        displayType={'text'}
                                                        fixedDecimalScale={true}
                                                        decimalScale={2}
                                                        decimalSeparator=','
                                                        suffix=' €'
                                                    /></Label.Detail> : null}

                                                </Label>


                                            ))}


                                            <Label color='teal' size='small' style={{marginBottom: '2px'}}>Total

                                                {!(historicElement.datas && historicElement.datas.savOriginId) ?
                                                <Label.Detail>
                                                    {historicElement.datas.diag.map((repair) => (repair.priceTtc)).reduce((x, y) => x + y, 0) > 0 ?
                                                        <NumberFormat
                                                            value={historicElement.datas.diag.map((repair) => (repair.priceTtc)).reduce((x, y) => x + y, 0)}
                                                            displayType={'text'}
                                                            fixedDecimalScale={true}
                                                            decimalScale={2}
                                                            decimalSeparator=','
                                                            suffix=' €'
                                                        /> : <>A diagnostiquer</>}</Label.Detail> :
                                                    <Label.Detail>0 € (SAV)</Label.Detail>}

                                            </Label>


                                        </Feed.Summary> : null
                                    }


                                    {historicElement.status === 'NEW_QUOTE_PROPOSAL' ?

                                        <>

                                            {extranet.intervention.interventionStatus === 'NEW_QUOTE_PROPOSAL' ?

                                                <Card fluid>
                                                    <Card.Content>
                                                        <Card.Header>Acceptez-vous le nouveau devis d'un total
                                                            de <NumberFormat
                                                                value={historicElement.datas.diag.map((repair) => (repair.priceTtc)).reduce((x, y) => x + y, 0)}
                                                                displayType={'text'}
                                                                fixedDecimalScale={true}
                                                                decimalScale={2}
                                                                decimalSeparator=','
                                                                suffix=' €'
                                                            /> ?</Card.Header>
                                                        <Card.Description>
                                                            Après acceptation du nouveau devis accepté, les réparations
                                                            seront effectuées sur votre appareil. En cas de refus, le
                                                            téléphone vous sera rendu.
                                                        </Card.Description>
                                                    </Card.Content>
                                                    <Card.Content extra>
                                                        <div className='ui two buttons'>
                                                            <Button basic color='red'
                                                                    onClick={() => setOpenRefusalModal(true)}>
                                                                Refuser
                                                            </Button>
                                                            <Button basic color='green'
                                                                    onClick={() => setOpenModal(true)}>
                                                                Accepter
                                                            </Button>

                                                            <Modal
                                                                onClose={() => setOpenRefusalModal(false)}
                                                                onOpen={() => setOpenRefusalModal(true)}
                                                                open={openRefusalModal}
                                                            >
                                                                <Modal.Header>Confirmation</Modal.Header>
                                                                <Modal.Content image>

                                                                    <Modal.Description>
                                                                        <Header>Confirmez-vous le refus ?</Header>



                                                                    </Modal.Description>
                                                                </Modal.Content>
                                                                <Modal.Actions>
                                                                    <Button color='black'
                                                                            onClick={() => setOpenRefusalModal(false)}>
                                                                        Annuler
                                                                    </Button>

                                                                    <Button
                                                                        content="Je confirme le refus"
                                                                        labelPosition='right'
                                                                        icon='checkmark'
                                                                        onClick={deniedNewQuote}
                                                                        positive
                                                                    />
                                                                </Modal.Actions>
                                                            </Modal>


                                                            <Modal
                                                                onClose={() => setOpenModal(false)}
                                                                onOpen={() => setOpenModal(true)}
                                                                open={openModal}
                                                            >
                                                                <Modal.Header>Confirmation</Modal.Header>
                                                                <Modal.Content image>

                                                                    <Modal.Description>
                                                                        <Header>Confirmez-vous le devis de <NumberFormat
                                                                            value={historicElement.datas.diag.map((repair) => (repair.priceTtc)).reduce((x, y) => x + y, 0)}
                                                                            displayType={'text'}
                                                                            fixedDecimalScale={true}
                                                                            decimalScale={2}
                                                                            decimalSeparator=','
                                                                            suffix=' €'
                                                                        /> ?</Header>
                                                                        <p>
                                                                            Merci de signer et de cliquer sur le
                                                                            bouton <b>Accepter</b> afin de valider votre
                                                                            consentement au nouveau devis.
                                                                        </p>

                                                                        <Segment style={{backgroundColor: 'grey'}}>
                                                                            <SignaturePad ref={(ref) => {
                                                                                toto(ref)
                                                                            }} canvasProps={{
                                                                                className: 'sigPad',
                                                                                width: 400,
                                                                                height: 130
                                                                            }}/>
                                                                        </Segment>

                                                                        {sigError ? <Message error>
                                                                            La signature est obligatoire
                                                                        </Message> : null}

                                                                    </Modal.Description>
                                                                </Modal.Content>
                                                                <Modal.Actions>
                                                                    <Button color='black'
                                                                            onClick={() => setOpenModal(false)}>
                                                                        Annuler
                                                                    </Button>
                                                                    <Button color='black' onClick={erase}>
                                                                        Effacer
                                                                    </Button>
                                                                    <Button
                                                                        content="Accepter"
                                                                        labelPosition='right'
                                                                        icon='checkmark'
                                                                        onClick={acceptNewQuote}
                                                                        positive
                                                                    />
                                                                </Modal.Actions>
                                                            </Modal>
                                                        </div>
                                                    </Card.Content>
                                                </Card>

                                                : null}


                                        </>
                                        : null}

                                    {/*{historicElement.status === 'QUOTE_VALIDATED' && extranet.intervention.items.filter((r) => r.category === 'SMA_KO' || r.category === 'DOX').length > 0 ?*/}
                                    {/*    <Feed.Extra>*/}
                                    {/*        {extranet.intervention.items.map((repair) => (*/}
                                    {/*            <Label>{repair.name}</Label>*/}
                                    {/*        ))}*/}
                                    {/*    </Feed.Extra> : null*/}
                                    {/*}*/}


                                </Feed.Content>
                            </Feed.Event>


                ))}

            </Feed>
        </>
    );

}

export default InterventionHistoricExtranet
