export function isOnlySmaKo(intervention) {
    const repairList = [];
    if(intervention.items) {
        for (const item of intervention.items) {
            if(!item.status) {
                repairList.push({
                    name: item.name,
                    category: item.category,
                    priceTtc: item.priceTtc
                })
            }
        }
        if (intervention.items.filter((r) => r.category === 'SMA_KO').length > 0 && repairList.length === 1) {
            return true;
        }
    }
    return false;
}


